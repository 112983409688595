<template>
  <div class="list">
    <notifications></notifications>
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.bezeichnung"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.raum"
                        :options="raeume"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.room')"
                      >
                        <span slot="no-options">{{ $t("global.nostatusfound") }}</span>
                      </v-select>
                      <label>{{ $t("global.room") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.workplaceslist')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="ml-4">
                    <router-link
                      v-if="berechtigungen.m_ressourcen.create"
                      class="btn btn-success"
                      :to="{ name: 'Arbeitsplatz', params: { id: 0 } }"
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-plus"
                        class="mr-2"
                      />
                      {{ $t("global.new") }}
                    </router-link>
                    <button
                      class="btn btn-primary ml-2"
                      @click="raumUpdaten"
                      v-if="berechtigungen.m_ressourcen.update"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-door-open"
                        class="mr-2"
                      />
                      {{ $t("global.changeroom") }}
                    </button>
                  </div>
                  <div class="mr-4 float-right">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_ressourcen.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />
                      {{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-compare="myCompare"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">{{ $t("global.nofurtherdatafound") }}</div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="arbeitsplatz-loeschen-modal"
      :selectedIds="selectedIds"
      :multiple="true"
      @confirmed="terminLoeschen"
    />

    <messagebox-count
      headerText="Termin(e) gelöscht"
      id="count-delete-alert-msgbox"
      :ok="true"
      :cancel="false"
      :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
    />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import LoeschenModal from "@/components/Modals/LoeschenModal";

import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";

import { LADE_AUSWAHLWERTE_RESSOURCEN } from "@/store/ressourcen/actions.type";

export default {
  name: "Arbeitsplatzliste",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.workplace");
      },
    };
  },
  mixins: [page, dashboard],
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  store,
  data() {
    return {
      arbeitsplaetze: [],

      fields: [
        { key: "selected", label: "" },

        { key: "displayNr", label: this.t$("global.display") },

        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
          formatter: (value, key, item) => {
            return value ? value : item.bezeichnung;
          },
        },
        {
          key: "raum.bezeichnung",
          sortable: true,
          label: this.$t("global.room"),
          formatter: (value, key, item) => {
            return value ? value : item.bezeichnung;
          },
        },
      ],
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      params.filterBezeichnung = this.setQueryParam(this.filters.bezeichnung);

      params.filterCount = this.setQueryParam(this.filters.count);

      params.raum = this.setQueryParam(this.filters.raum);

      params.raumFilter = this.setQueryParam(this.filters["raum.bezeichnung"]);

      return params;
    },
    raeume: {
      get() {
        return this.$store.state.ressourcen.raeume;
      },
    },
  },

  created() {
    if (this.raeume.length == 0)
      this.$store.dispatch(`ressourcen/${LADE_AUSWAHLWERTE_RESSOURCEN}`);

    this.initFilter("arbeitsplatz-liste", "arbeitsplatz/", true);
  },
  mounted() {
    this.setHeaders("global", [
      "",
      "Display",
      "designation",
      "room",
    ]);
  },
  methods: {
    setFilterDozent(dozent) {
      this.$set(this.filters, "dozent", dozent);
    },

    setVeranstaltung(veranstaltung) {
      this.$set(this.filters, "veranstaltung", veranstaltung);
    },

    details(arbeitsplatz) {
      this.$router.push({
        name: "Arbeitsplatz",
        params: { id: arbeitsplatz.id, anzeige: 0 },
      });
    },

    raeumeGeaendert() {
      this.resetLoading(this);
    },

    raumUpdaten() {
      this.$bus.$emit("openTerminRaumModal");
    },

    terminLoeschen() {
      this.delete("arbeitsplatz/", this.selectedIds);
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "arbeitsplatz-loeschen-modal");
    },
  },
};
</script>

<style>
</style>
